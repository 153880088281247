import accountantApi from "api/accountant"
import { IAccountant, IUser } from "api/types"
import Modal from "components/Modal"
import { availableCertifications } from "constants/certifications"
import useMaybeNotSetupUser from "contexts/MaybeNotSetupUser/useMaybeNotSetupUser"
import useUser from "contexts/User/useUser"
import { handleError } from "helpers/errors"
import { FC, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

const CertificationPopup: FC = () => {
  const { user, setUser } = useMaybeNotSetupUser()
  const isAccountant = user?.type === "accountant"
  const [sp, setSP] = useSearchParams()
  const isCertificationUpdate = sp.get("update") === "certification"
  const name = availableCertifications[Number(sp.get("certification"))]
  const open = isAccountant && isCertificationUpdate && name !== undefined
  const [certification, setCertification] = useState<string>("")
  const [submitting, setSubmitting] = useState(false)

  const onCancel = () => {
    if (submitting) return
    setSP(old => {
      old.delete("update")
      old.delete("certification")

      return old
    })
  }

  const onEdit = () => {
    if (submitting) return
    if (!certification) return handleError("Please enter your certification link")
    if (certification && !certification.includes("credly.com/badges/")) {
      handleError("Enter a valid Credly Link")

      return
    }
    setSubmitting(true)
    accountantApi.updateCertification(name, certification).then(() => {
      setUser?.(old => ({
        ...old,
        certifications: {
          ...(old as IAccountant).certifications,
          [name]: certification,
        },
      } as IUser))
      setSP(old => {
        old.delete("update")
        old.delete("certification")

        return old
      })
    }).finally(() => setSubmitting(false))
  }

  useEffect(() => {
    if (open) {
      setCertification(user?.certifications?.[name] || "")
    }
  }, [user, open, name])

  return (
    <Modal open={open} close={!submitting ? onCancel : () => {}}>
      <div className="bg-white rounded-2.5 divide-y divide-y-slate-200 flex-cool h-max my-12 mx-4 max-w-[768px] min-w-[512px]">
        <header className="flex items-center justify-start gap-2.5 p-4">
          <span className="font-medium text-2xl text-slate-900 leading-xl">
            Add your&nbsp;
            <b>{name}</b>
            <br />Certification
          </span>
        </header>
        <main className="flex-cool p-4 gap-3">
          <div className="flex-cool gap-3">
            <input
              type="text"
              placeholder="Certification Link"
              value={certification}
              onChange={e => setCertification(e.currentTarget.value)}
            />
          </div>
        </main>
        <footer className="p-4 flex items-center justify-between gap-2.5">
          <button className="btn-secondary" disabled={submitting} onClick={!submitting ? onCancel : () => {}}>
            Cancel
          </button>
          <button disabled={submitting} onClick={onEdit} className="btn-primary">Save Changes</button>
        </footer>
      </div>
    </Modal>
  )
}

export default CertificationPopup
